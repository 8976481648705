.FileItem {
    position: relative;
    
    .valid {
      border-color: #63AA9C;
  
      .MuiTypography-body1 {  
        color: #63AA9C !important;
      }
      
    }
}